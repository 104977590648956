.home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 200px 0 100px 0;
  background-color: #fff;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home img {
  height: 500px;
  width: 240px;

}
.home-options img {
  height: 80px;
  width: 200px;
  margin-right: 16px;
}

.btn-light {
  color: #212529;
  background-color: #eaeaea;
  border-color: #eaeaea;
  border: 1px solid transparent;
  padding: 12px 24px;
  border-radius: 8px; 
}

.btn-light img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.home p {
  color: #48596c;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 2em;
  width: 96%;
}

.home .tagline {
  color: #48596c;
  font-size: 104px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  display: inline-block;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.home h2 {
  color: #48596c;
  line-height: .98em;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 2em;
  padding-left: 1%;
  width: 96%
}

.home .home-options {
  margin-top: 10px;
}

@media screen and (max-width: 667px){
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 200px 0 100px 0;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .tagline {
    color: #48596c;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    display: inline-block;
    text-transform: uppercase;
    
  }
}

@media screen and (max-width: 912px){
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 150px 0px 0px 70px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .tagline {
    color: #48596c;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    display: inline-block;
    text-transform: uppercase;
    
  }
}
@media screen and (max-width: 820px){
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 150px 0px 0px 70px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .tagline {
    color: #48596c;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    display: inline-block;
    text-transform: uppercase;
    
  }
}

@media screen and (max-width: 375px){
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 150px 0px 800px 70px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .tagline {
    color: #48596c;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    display: inline-block;
    text-transform: uppercase;
    
  }
}


@media screen and (max-width: 414px){
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 150px 0px 800px 70px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .tagline {
    color: #48596c;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    display: inline-block;
    text-transform: uppercase;
    
  }
}

@media screen and (max-width: 540px){
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 150px 0px 800px 70px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .home .tagline {
    color: #48596c;
    font-size: 50px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    display: inline-block;
    text-transform: uppercase;
    
  }
}