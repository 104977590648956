@font-face{
  font-family: "Poopins Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter/Inter-Medium.ttf");
}

@font-face{
  font-family: "Poopins Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter/Inter-Regular.ttf");
}

@font-face{
  font-family: "Poopins SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf");
}
