
.privacy {
    margin-top: 0;
    padding: 100px 100px 100px 100px;
    background-color: #fff;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

 
  .privacy p {
    color: #48596c;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 2em;
    width: 96%;
  }
  .privacy li {
    color: #48596c;
    font-size: 25px;
    letter-spacing: 1px;
    line-height: 2em;
    width: 56%;
  }
  .privacy h1 {
    color: #48596c;
    text-align: center;
    font-size: 25px;
    letter-spacing: 1px;
    line-height: 2em;
  }
  
  @media screen and (max-width: 800px){
    .privacy {
      margin-top: 0;
      padding: 10px 10px 10px 10px;
      background-color: #fff;
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      text-align: justify;
    }
    .privacy li {
      color: #48596c;
      font-size: 25px;
      letter-spacing: 1px;
      line-height: 2em;
      width: 100%;
      text-align: left;
    }

    .privacy p {
      color: #48596c;
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 2em;
      width: 96%;
    }
  
  }