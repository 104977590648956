
.features {
  margin-top: 0;
  padding: 100px 0 100px 0;
  background-color: #fff;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.features img {
  height: 500px;
  width: 240px;
}
.skill-bx {
  background-color: red;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin: -60px;
}

.features h2 {
  
padding:  50px ;
}

.features h1 {
  color: #486c5e;
  line-height: .98em;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 2em;
  margin: 0 auto;
  padding-left: 1%;
  width: 96%
}

.skill-slider {
  width: 100%;
  margin: 0 auto;
  position: left;
  border-top-left-radius: 8%;
  border-bottom-left-radius: 8%;
}

.features p {
  color: #48596c;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 2em;
  width: 96%;
  text-transform: lowercase !important;
  font-weight: 300;
}


.item1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.item5 {
  position: absolute;
  top: 100%;
  right: 30%;
  z-index: 6;
  transform: translate(-10%, -140%);
  z-index: 1;
}

.item2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.item3 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.item4 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}


@media screen and (max-width: 375px){

  .features h2 {
  
    padding:  500px  10px  10px 0px ;
    }
  
}

@media screen and (max-width: 414px){

  .features h2 {
  
    padding:  500px  10px  10px 0px ;
    }
  
}

@media screen and (max-width: 540px){

  .features h2 {
  
    padding:  500px  10px  10px 0px ;
    }
  
}